import React from "react"
import loadable from "@loadable/component"
import { MAIN_BUTTON } from "@constants"
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "../../../../links";
import { ButtonWrapper } from "../../../../styles/CommonStyles"
import { useAboutSectionConfiguration } from "../../hooks";
import {
  MAIN_PAGE_ABOUT_COMPANY_BUTTON_TEXT,
  MAIN_PAGE_ABOUT_HEADING,
  MAIN_PAGE_ABOUT_PARAGRAPH,
} from "../../constants";
import {
    StyledAboutSectionContainer,
    StyledAboutInfoItemsContainer,
    StyledButtonContainer,
  } from "./AboutSection.style";

const PageHeading = loadable(() => import("@common/PageHeading/PageHeading"));
const Button = loadable(() => import("@common/Button/Button"));

const AboutSection = () => {
  const { companyStatisticBlocks } = useAboutSectionConfiguration();
  return (
    <StyledAboutSectionContainer>
      <PageHeading
        heading={MAIN_PAGE_ABOUT_HEADING}
        mainParagraph={MAIN_PAGE_ABOUT_PARAGRAPH}
      />
      <StyledAboutInfoItemsContainer>
        {companyStatisticBlocks}
      </StyledAboutInfoItemsContainer>
      <StyledButtonContainer>
        <ButtonWrapper isCentered isWhite>
          <Button
            text={MAIN_PAGE_ABOUT_COMPANY_BUTTON_TEXT}
            type={MAIN_BUTTON}
            href={GLOBAL_APPLICATION_INTERNAL_LINKS.ABOUT_US_PAGE}
          />
        </ButtonWrapper>
      </StyledButtonContainer>
    </StyledAboutSectionContainer>
  )
}

export default AboutSection;
