import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import useComponentsGenerator from "@hooks/useComponentsGenerator";
import { ABOUT_COMPANY_STATISTICS } from "../../constants";

const AboutSectionItem = loadable(() => import("../../components/AboutSection/components/AboutSectionItem"));

const useAboutSectionConfiguration = () => {
  const [componentElements, generateComponents] = useComponentsGenerator(ABOUT_COMPANY_STATISTICS);
  const [companyStatisticBlocks, setCompanyStatisticBlocks] = useState([]);

  useEffect(() => {
    setCompanyStatisticBlocks(
      generateComponents((aboutInfo, index) => {
        componentElements.push(<AboutSectionItem key={index} aboutInfo={aboutInfo} />)
      })
    )
  }, [])

  return { companyStatisticBlocks };
}

export default useAboutSectionConfiguration;
