import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const StyledAboutSectionContainer = styled.div`
    margin: 7.5rem 0;
    padding: 0 10.1875rem;

    & .page-heading {
        text-align: center;
        margin-bottom: 3rem;

        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            margin-bottom: 2.5rem;

            &__heading {
                text-align: center;
                margin-bottom: 1.5rem;
            }
        }

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
            text-align: left;
            padding: 0 1.5rem;

            &__heading {
                text-align: left;
            }
        }
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin: 5rem 0;
        padding: 0 6.25rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin: 0 0 2.5rem 0;
        padding: 0;
    }
`;

export const StyledAboutInfoItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const StyledButtonContainer = styled.div`
    width: 19.125rem;
    margin: 2.5rem auto 0;
`;
